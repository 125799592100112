import { statusList } from "../static"
import configuration from "../config.json";

const isBidNotStarted = (record) => {
  const status = record.status || record?.bids_master?.status
  return status === 'Not Started' || (record.start_date && (new Date(record.start_date).getTime() > new Date().getTime()))
}

const isBidFinished = (record) => {
  return record.status === 'In Progress' || record.status === 'Not Started'  ? (!record.bid_remaining_time) : true
}

export const formatToNum = (val = "") => {
  return Number(val.replace(/[^0-9.-]+/g,""));
}

export const extractError = (data = {}) => {
  let msg = "";
  for (let key in data) {
    msg = `${key} ${data[key]}. `
  }
  return msg
}

export const getSeconds = (record = {}) => {
  const date = record.bids_master?.status === 'In Progress' ? record?.bids_master?.end_date : isBidNotStarted(record)
    ? record.bids_master?.start_date
    : new Date()

  var t1 = new Date('YYYY', 'MM', 'DD', 0, 0, 0, 0);
  var t2 = new Date('ZZZZ', 'NN', 'EE', 0, 0, 0, 0);
  var dif = t1.getTime() - t2.getTime();

  var Seconds_from_T1_to_T2 = dif / 1000;
  var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
  return Seconds_Between_Dates
}

export const getBidUserStatus = (status = 'Lost') => {
  status = status.toString().toLocaleUpperCase()
  switch (status) {
    case 'WON': {
      return "(Won)"
    }
    case 'LOST': {
      return "Lost"
    }
    default: {
      return "Lost"
    }
  }
}

export const formattedBids = (data = []) => {
  return data.map(record => {
    let currentBid =  record?.bids_master?.highest_price || 0;
    const rank = record.lot_publish ? record.lot_publish.rank : record.rank;
    const color = ['Winning', 'Won'].indexOf(rank.trim()) > -1 ? 'win' : 'lose';
    const myBid = record?.bid_price || record?.bids_master.my_price || 0;
    let bidStatus = record.bids_master ? record.bids_master.bid_user_status : record.user_bid_status;
    bidStatus = getBidUserStatus(bidStatus);

    return {
      id: record.id,
      currentBid: currentBid,
      bidStatus: bidStatus,
      myBid: myBid,
      color: color,
      currencySymbol: record.currency_symbol || configuration.currency_symbol,
      title: record?.lot_publish?.lot_name || record.title,
      bidRemainingTime: record?.lot_publish ? record.lot_publish.bid_remaining_time : record?.bid_remaining_time,
      delivery: record?.lot_publish?.storage_location || record.storage_location,
      current_bid: (record?.bid_price || record?.bids_master.my_price),
      quantity: record?.lot_publish?.items_count || record?.item_count,
      mrp: (record?.lot_publish?.mrp || record?.bids_master?.mrp),
      endDate: record?.lot_publish?.end_date || new Date(record?.bids_master?.end_date * 1000),
      startData: record?.lot_publish?.start_date || new Date(record?.bids_master?.start_date),
      buyPrice: (record?.lot_publish?.buy_now_price || record.bids_master.buy_now_price),
      bidPrice: (record?.lot_publish?.floor_price || record.bids_master.my_price),
      lotImage: record?.lot_publish?.lot_image_urls?.[0] || record?.medium_image_path?.[0],
      bidNotStarted: isBidNotStarted(record),
      bidFinished: isBidFinished(record),
      rank: rank.trim() ? `(${rank})` : '',
      offPercentage: parseInt((100 * (record?.lot_publish?.buy_now_price || record.bids_master.buy_now_price)) / (record?.lot_publish?.mrp || record?.bids_master?.mrp)),
      lot_publish_id: record?.lot_publish?.id || record?.id,
      status: record?.lot_publish?.status || record?.bids_master?.status,
      bid_id: record?.bids_master?.id
    }
  })
}

export const formatOrders = (data = []) => {
  return data.map(order => {
    const isMoq = order?.lot_summary?.lot_type === 'moq';
    if(isMoq) {
      order.lot_summary.lot_publish_id = order.lot_publish_id
    }
    let orderItem = isMoq ? order.lot_summary : order?.order_items?.[0] || {};
    return {
      name: orderItem.name || orderItem.lot_name,
      bid_id: orderItem.bid_id,
      order_number: order.order_number || order.lot_number,
      created_at: !order.ip_address ? new Date(order.order_date * 1000) : new Date(order.order_date),
      total_payable_amount: (order.amount),
      paid_amount: (order.paid_amount),
      payable_amount: (order.amount - (order.paid_amount || 0)),
      order_status: order.order_status,
      currencySymbol: order.currency_symbol || configuration.currency_symbol,
      quantity: order.quantity,
      order_items: isMoq ? [order.lot_summary] : order.order_items,
      lot_publish_id: orderItem?.lot_publish_id || orderItem?.inventory_id,
      order_id: orderItem?.lot_publish_id ? "" : orderItem?.order_id
    }
  });
}

const currencyLocalize = (value) => {
  switch(value) {
    case 'USD': 
      return 'en-US';
      break;
    case 'AED': 
      return 'ar-AE';
      break;
    default:
      return 'en-IN'
  }
}

export const formatCurrency = (value = '0', currencySymbol = configuration.currency_symbol) => {
  value = isNaN(value) ?  parseInt(value?.replaceAll(',', '')) : value;
  return new Intl.NumberFormat(currencyLocalize(currencySymbol)).format(value)
}

export const formatDate = (date) => {
  return date ? new Date(date * 1000) : new Date()
}

export const formatNumber = (value) => {
  return value && parseInt(value.toString().replace(/,/g, ""))
}

export const savePercent = (mrp, sellingPrice) => {
  return (((mrp - sellingPrice) / mrp) * 100).toFixed(2)
}

export const formatLots = (data = []) => {
  return data.map((lot) => {
    let increment_slab = lot?.bid_value ? lot.bid_value : lot?.bids_master?.increment_slab || 0;
    let floor_price = lot.floor_price || lot?.bids_master?.floor_price || 0;
    let my_price = lot.bid_amount || lot?.bids_master?.my_price || 0;
    let highest_price = lot?.bids_master?.highest_price || 0;
    let current_bid_price = (my_price && (my_price > highest_price)) ? (my_price + increment_slab) : (highest_price && (highest_price > floor_price)) ? (highest_price + increment_slab) : (increment_slab + floor_price)
    let lotImages = (lot.lot_image_urls || lot.thumb_image_path || []).filter(img => img)
    let currentBid = highest_price ? highest_price : floor_price;
    const bidColor = lot.rank.trim() ? ((['Winning', 'Won'].indexOf(lot.rank) > -1) ? 'win' : 'lose') : 'no-bid';
    
    const currencySymbol = lot.currency_symbol || configuration.currency_symbol;
    return {
      // parseInt((100 * ( (lot?.mrp || lot?.price) - (lot?.buy_now_price || lot?.bids_master?.buy_now_price)) / (lot?.mrp || lot?.price)))
      bid_finished: isBidFinished(lot?.bids_master ? { ...lot, end_date: formatDate(lot?.bids_master?.end_date) } : lot),
      rank: lot.rank?.trim(),
      highest_price: highest_price, 
      my_price: my_price,
      increment_slab: increment_slab,
      currency_symbol: currencySymbol,
      bid_not_start: isBidNotStarted(lot?.bids_master ? { ...lot, start_date: formatDate(lot?.bids_master?.start_date) } : lot),
      offPercentage: savePercent(lot?.mrp || lot?.price, lot?.buy_now_price || lot?.moq_lot_lowest_buy_price || lot?.bids_master?.buy_now_price),
      bidOffPercentage: savePercent(lot?.mrp || lot?.price, current_bid_price),
      storage_location: lot.storage_location,
      futureBid: lot.bids_master ? lot.bids_master.future_bid : (lot.status === statusList.NotStarted),
      currentBid: (currentBid),
      grade_name: lot.grade_name.replace(/^Refurbished\s*/, ''),
      lot_name: lot.lot_name ? lot.lot_name : lot.bids_master.name,
      bidRemainingTime:  lot.bids_master ? lot.bids_master.bid_remaining_time : lot?.bid_remaining_time,
      color: bidColor,
      moq_lot_lowest_buy_price: lot?.moq_lot_lowest_buy_price,
      items_count: lot.items_count || lot.item_count,
      floor_price: (lot.floor_price || lot?.bids_master?.floor_price),
      bid_amount: (lot?.bid_amount || lot?.bids_master?.my_price),
      delivery_by: lot.bids_master ? lot.bids_master.shipping_type : lot.delivery_by,
      bidding_method: lot.bidding_method || lot.lot_type,
      buy_now_price: (lot.buy_now_price || lot.moq_lot_lowest_pricing || lot?.bids_master?.buy_now_price),
      end_date: lot.end_date || new Date(lot?.bids_master?.end_date * 1000),
      start_date: lot.start_date || new Date(lot?.bids_master?.start_date * 1000),
      id: lot.id,
      lot_image_urls: lotImages,
      manifest_url: lot.manifest_url,
      org_image_url: lot.org_image_url,
      status: lot.status || lot?.bids_master?.status,
      place_bid_amount : (current_bid_price),
      bid_id: lot?.bids_master?.id,
      bid_mrp : (lot?.mrp || lot?.price)

    }
  })
}

const compare = (a, b) => {
  if (a.price > b.price) {
    return -1;
  }
  if (a.price < b.price) {
    return 1;
  }
  return 0;
}

const getRemainSeconds = (e = {}) => {
  let toDate = null;
  if ((e.bids_master.status === 'In Progress') && (new Date(e.bids_master.start_date * 1000) > new Date())) {
    toDate = new Date(e.bids_master.start_date * 1000)
  } else if ((e.bids_master.status === 'Not Started') && (new Date(e.bids_master.start_date * 1000) < new Date())) {
    toDate = new Date(e.bids_master.end_date * 1000)
  }

  if (toDate) {
    var t2 = new Date();
    var dif = toDate.getTime() - t2.getTime();

    var Seconds_from_T1_to_T2 = dif / 1000;
    var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
  }
  return toDate ? Seconds_Between_Dates : undefined;
}

const getRank = (detail = {}) => {
  return ((detail.my_price > 0) ? ((detail.highest_price > detail.my_price) ? "(Losing)" : "(Winning)") : "")
}

export const formatLotDetails = (detail = {}) => {
  let lotImages = (detail?.lot_summary?.lot_image_urls || detail?.inventory?.medium_image_path || []).filter(img => img)
  const highestPrice = detail?.bids_master?.highest_price;
  const floorPrice = detail?.lot_summary?.floor_price || detail?.bids_master?.floor_price || 0;
  const rankVal = detail.lot_summary ? (detail?.lot_summary?.rank) : detail.bids_master.rank;
  const bidColor = rankVal?.trim() ? ((["Winning", "Won", "(Winning)"].indexOf(rankVal) > -1) ? 'win' : 'lose') : 'no-bid';
  let currentBid = highestPrice ? highestPrice : floorPrice;
  let shipping = detail.bids_master ? detail.bids_master.shipping_type : detail.lot_summary.delivery_by;

  const currencySymbol = detail?.lot_summary?.currency_symbol || configuration.currency_symbol;
  return {
    lot_summary: {
      currencySymbol: currencySymbol,
      shipping: shipping,
      futureBid: detail.lot_summary ? (detail.lot_summary.status === statusList.NotStarted) : detail.bids_master.future_bid,
      currentBid: (currentBid),
      bidRemainingTime: detail?.bids_master ? detail.bids_master.bid_remaining_time : detail?.lot_summary?.bid_remaining_time,
      lot_type: detail?.moq_lot_pricings ? 'moq' : 'hybrid',
      id: detail.lot_summary?.id || detail.bids_master.id,
      shortDesc: (detail.lot_summary ? detail.lot_summary.lot_description : detail?.inventory?.description) || "",
      lot_name: detail?.lot_summary?.lot_name ? detail?.lot_summary?.lot_name : detail?.bids_master?.name,
      status: detail?.lot_summary?.status || detail?.bids_master?.status,
      color: bidColor,
      storage_location: detail?.lot_summary?.storage_location || detail?.inventory?.storage_location,
      start_date: detail?.lot_summary?.start_date || formatDate(detail?.bids_master?.start_date),
      end_date: detail?.lot_summary?.end_date || formatDate(detail?.bids_master?.end_date),
      buy_now_price: detail?.lot_summary?.buy_now_price || detail?.bids_master?.buy_now_price,
      mrp: (detail?.lot_summary?.mrp || detail?.bids_master?.mrp),
      lot_image_urls: lotImages,
      grade_name: (detail?.lot_summary?.grade_name || detail?.inventory?.grade_name).replace(/^Refurbished\s*/, ''),
      rank: rankVal.trim() ?`(${rankVal})` : '',
      maximum_lots_per_buyer: detail?.lot_summary?.maximum_lots_per_buyer || 1,
      available_quantity: detail?.available_quantity || 1,
      items_count: detail?.lot_summary?.items_count || detail?.inventory?.item_count,
      bid_value: detail?.lot_summary?.bid_value || 100,
      floor_price: (detail?.lot_summary?.floor_price || detail?.bids_master?.floor_price),
      bid_price: (detail?.lot_summary?.bid_amount || detail?.bids_master?.my_price),
      increment_slab: (detail?.lot_summary?.increment_slab || detail?.bids_master?.increment_slab),
      manifest_url: detail?.lot_summary?.manifest_url || detail?.inventory?.manifest_url,
      highest_price: detail?.bids_master?.highest_price,
      offPercentage: savePercent(detail?.lot_summary?.mrp || detail?.bids_master?.mrp, detail?.lot_summary?.buy_now_price || detail?.bids_master?.buy_now_price),
      marketplace_id: detail?.lot_summary?.marketplace_id || detail?.inventory?.marketplace_id
    },
    top_Brand: detail?.top_Brand?.map(a => {return {...a, currencySymbol: a.currencySymbol || configuration.currency_symbol}}),
    top_Category: detail?.top_Category?.map(a => { return {...a, currencySymbol: a.currency_symbol || configuration.currency_symbol}}),
    moq_lot_pricings: detail?.moq_lot_pricings?.sort(compare) || []
  }
}

export const formatLotManifestDetail = (data = []) => {
  return data.map(item => {
    const obj = {
      image_urls: item?.image_urls,
      description: item?.description || item?.title,
      brand: item?.brand,
      quantity: item.quantity,
      category: item?.category,
    }

    if(configuration.showMRP) {
      obj.mrp = (item.currency_symbol || configuration.currency_symbol) + formatCurrency((item?.mrp ? item.mrp : item?.price) || 0)
    }
    
    return obj;
  })
}

export const formatfilterOptions = (options = {}) => {
  const editKeys = (key) => (
    key?.id ? { label: key?.display_name, value: key?.id } : { label: key.replace(/^Refurbished\s*/, ''), value: key }
  )
  const getUniqueValue = (key) => {
    return key.filter((item, pos, self) => {
      return item?.id ? self.map(value => value.id).indexOf(item.id) === pos
        : self.indexOf(item) === pos
    })
  }
  return {
    categories: getUniqueValue(options.categories).map(cat => editKeys(cat)),
    conditions: getUniqueValue(options.conditions).map(con => editKeys(con)),
    location: getUniqueValue(options.location).map(loc => editKeys(loc)),
    sub_categories: getUniqueValue(options.sub_categories).map(sub_cat => editKeys(sub_cat))
  }

}


export const addLeadingZero = (value = 0) => {
  return value > 9 ? value : `0${value}`;
}


export const ConvertSectoDay = (n) => {
  var day = 0;
  var hour = Math.floor(n / 3600);
  var minutes = Math.floor(n % 3600 / 60);
  var seconds = Math.floor(n % 3600 % 60);
  minutes = minutes < 10 ? `0${minutes}` : minutes
  hour = hour < 10 ? `0${hour}` : hour
  seconds = seconds < 10 ? `0${seconds}` : seconds
  // var day = parseInt( n / (24 * 3600));

  // n = n % (24 * 3600);
  // var hour = ('0' + parseInt(n / 3600)).slice(-2);

  // n %= 3600;
  // var minutes = ('0' + parseInt(n / 60)).slice(-2);

  // n %= 60;
  // var seconds = ('0' + parseInt(n)).slice(-2);

  return {
    day, hour, minutes, seconds
  }
}
