<template>
  <div v-if="configuration.countryLocale.locale === 'uae'">
    <UaeTnc />
  </div>
  <div v-else>
    <GlobalTnc />
  </div>
</template>

<script>
import GlobalTnc from "./global.vue";
import UaeTnc from "./uae.vue";
import configuration from "../../config.json";

export default {
  setup() {
    return {
      configuration
    };
  },
  components: {
    GlobalTnc,
    UaeTnc
  }
};
</script>
