<template>
  <section class="faq">
    <v-container>
      <h6 class="faq-title mb-10 text-center"><u>Terms of purchase</u></h6>
      <h6 class="faq-ques">Bid Value</h6>
      <p>Bid value includes VAT and logistics costs.</p>
      <h6 class="faq-ques">Cancellation</h6>
      <p>Upon ordering a lot, it's crucial to fulfill the payment commitment. Failure to do so may lead to
        penalties or being blacklisted on the platform. Amazon (Seller) or Rebirch reserves the right, at
        our discretion, to refuse or cancel any ordered lot, notifying you promptly via email, phone,
        SMS, or WhatsApp. Refunds will be processed within 24 hours of cancellation, or a credit note
        will be shared for the same.
      </p>
      <h6 class="faq-ques">Payment Terms and Purchase</h6>
      <p>Upon approval of your order for the relevant lot, please ensure full payment is made within 24
        hours. Failure to do so will result in cancellation of the order. Partial payments cannot be
        accepted.
      </p>

      <p>Subject to your payment of the lot price/value within the specified period, you can expect to
        receive the inventory within 15 to 20 days. If you fail to accept delivery from Amazon, the
        inventory will be returned to them, and your payment will be forfeited.
      </p>

      <h6 class="faq-ques">Item Condition</h6>
      <p>The condition of Amazon inventory will be specified as either "Brand new seal packed" or
        "Customer returned". In the second case, the products may be functionally good with minor
        or major scratches, defective, or in damaged condition. The inventory will be shipped to
        the buyer in the same condition as described.
      </p>

      <h6 class="faq-ques">Shipment</h6>
      <p>After payment for the ordered lot, the buyer will receive the invoice and information regarding
        inventory delivery. The price value includes logistics costs.
      </p>
      <h6 class="faq-ques">Claims Process</h6>
      <h6 class="faq-ques">Amazon shipment directly to Buyers</h6>

      <h6 class="faq-ques">Buyer Communication</h6>
      <ol>
        <li>Rebirch will provide the processed AWB number to the buyer. </li>
        <li>Each box received from Amazon will include a list on the box with the ASINs of all items inside. </li>
        <li>Each item received from Amazon will display LPN and ASIN information on its box.</li>
      </ol>

      <h6 class="faq-ques">In Amazon there can be broadly six types of claims:</h6>
      <ol>
        <li>Less number of Master Cartons received as per POD.</li>
        <li>Master Carton received in open condition </li>
        <li>Units inside the box are fake. </li>
        <li>Empty unit box received. </li>
        <li>Mismatch Units received</li>
        <li>Units not received (Missing Item)</li>
      </ol>
      <p>There is a separate process for raising a claim for all these 6 reasons. Any claim must be
        submitted within 3 days of material receipt. Following process must be followed while
        raising claims:
      </p>

      <ol style="font-weight: bold;">
        <li><h6 class="faq-ques">Less Number of Master Cartons Received:</h6>
          <ol type="a">
            <li>Identify the AWB of the carton that is missing. In case of multiple
              cartons that are missing, identify the AWB of each.</li>
            <li>Clearly write on the POD, all the AWBs of the missing cartons and
              received cartons separately.</li>
            <li>Ensure the transporter signs the POD with the missing AWBs identified</li>
            <li>Please take the clear images of POD.</li>
            <li>Share details of missing AWB numbers and images of POD to
              <a href="mailto:support@rebirch.ae">support@rebirch.ae</a>
            </li>
          </ol>
        </li>
        <li><h6 class="faq-ques">Open Master Carton Received:</h6>
          <ol type="a">
            <li>Receive the box under video camera</li>
            <li>Take images of the box</li>
            <li>You can choose to reject the open box. In such a case, clearly mention
              this in POD as "Box rejected due to being in damaged/open condition".
              Take the signature of the carrier and image of this signed doc.
            </li>
            <li>If you choose to accept the box, count the units inside the box under
              camera. For any missing units, follow steps mentioned in point number (6).
            </li>
          </ol>
        </li>
        <li><h6 class="faq-ques">Units Inside the Box are Fake</h6>
          <ol type="a">
            <li>Unbox the master carton under the video camera with AWB number
              and SLAM label clearly visible. Video must be uncut i.e. the video
              should be shared in one go, starting from opening the master carton
              with clearly showing AWB No, counting and opening the product boxes
              by clearly showing LPN No
            </li>
            <li>It should be clearly visible that the box is being opened for the first
              time with Amazon seal tape clearly visible
            </li>
            <li>Carefully take out units one by one under camera</li>
            <li>Identify fake units and show it very clearly under camera.</li>
            <li>Take images of fake products from 6 sides - top, bottom and four sides
              along with LPN number.
            </li>
            <li>Mark the images with missing attributes or additional attributes that
              indicate that the unit is fake. For example, if the serial number is
              normally available on the unit but not available on units delivered by
              Amazon, you must highlight in the image where the serial number is
              missing.
            </li>
            <li>Rename the images with ASIN number and LPN number.</li>
            <li>In case of fake the unique characteristics used to identify fake from the
              genuine original product with images highlighting the same in the first
              instance.
            </li>
            <li>Unboxing video for the disputed ASIN- please record a video clearly
              shows AWB/SLAM label and LPN, video should be clear, without cuts
              and from the time the seal/Amazon tape is being cut. Before unboxing,
              all 6 sides of the box should be shown.
            </li>
            <li>Share the unboxing videos along with images with to
              <a href="mailto:support@rebirch.ae">support@rebirch.ae</a>
            </li>
          </ol>
        </li>
        <li><h6 class="faq-ques">Empty box inside master carton</h6>
          <ol type="a">
            <li>Unbox the master carton under the video camera with AWB number
              and SLAM label clearly visible. Video must be uncut i.e. the video
              should be shared in one go, starting from opening the master carton
              with clearly showing AWB No, counting and opening the product boxes
              by clearly showing LPN No.
            </li>
            <li>It should be clearly visible that the box is being opened for the first
              time with Amazon seal tape clearly visible
            </li>
            <li>Carefully take out units one by one under camera.</li>
            <li>Identify the Empty boxes and show it very clearly under camera.</li>
            <li>Take images of empty box from six sides</li>
            <li>Rename the images with ASIN number and LPN number.</li>
            <li>Share the unboxing videos along with images with to
              <a href="mailto:support@rebirch.ae">support@rebirch.ae</a>
            </li>
          </ol>
        </li>
        <li><h6 class="faq-ques">Mismatch units received</h6>
          <ol type="a">
            <li>Unbox the master carton under the video camera with AWB number
              and SLAM label clearly visible. Video must be uncut i.e., the video
              should be shared in one go, starting from opening the master carton
              with clearly showing AWB No, counting and opening the product boxes
              by clearly showing LPN No.
            </li>
            <li>It should be clearly visible that the box is being opened for the first
              time with Amazon seal tape clearly visible
            </li>
            <li>Carefully take out units one by one under camera</li>
            <li>Identify mismatched units and show it very clearly under camera.</li>
            <li>Take images of mismatched products from 6 sides along with LPN number</li>
            <li>Note down the name, model and brand of the product received physically.</li>
            <li>Rename the images with ASIN number and LPN number.</li>
            <li>Share images, videos and details of actual products received to
              <a href="mailto:support@rebirch.ae">support@rebirch.ae</a>
            </li>
          </ol>
        </li>
        <li><h6 class="faq-ques">Product not received (Missing Item)</h6>
          <ol type="a">
            <li>Unbox the master carton under the video camera with AWB number
              and SLAM label clearly visible. Video must be uncut i.e. the video
              should be shared in one go, starting from opening the master carton
              with clearly showing AWB No, counting and opening the product boxes
              by clearly showing LPN No.
            </li>
            <li>It should be clearly visible that the box is being opened for the first
              time with Amazon seal tape clearly visible
            </li>
            <li>Carefully take out units one by one under camera</li>
            <li>Share the details of the unit not received along with video to
              <a href="mailto:support@rebirch.ae">support@rebirch.ae</a>
            </li>
          </ol>
        </li>
      </ol>

      <h6 class="faq-ques">Important Points:</h6>
      <ol>
        <li>*Amazon will not accept disputes with shrink wrap for the shipment received. So, the
          unboxing video of the master carton should be shared after removing the shrink wrap.
          Please ensure that there is no shrink wrap or cello tape in the unboxing videos on the
          master carton.
        </li>
        <li>Amazon needs one unboxing video of the entire package and not multiple videos.</li>
        <li>No claims for missing accessories.</li>
        <li>In all the instances, size of 6 images must not exceed 20 MB and size of video must
          not exceed 24 MB or Google link for videos.
        </li>
      </ol>
      <p>Kindly share the captured images, videos and all the above requested information to
        <a href="mailto:support@rebirch.ae">support@rebirch.ae</a>
      </p>
    </v-container>
  </section>
</template>
<style scoped>
  .faq ul, .faq ol {
    margin-left: 2%;
    font-weight: normal;
  }
  .faq ul li, .faq ol li {
    padding-left: 1%;
  }
</style>
