import { scbAxiosInstance } from "../services";
import { useAlertStore } from "../stores";

export const getOTP = async (data = {}) => {
    const resp = await scbAxiosInstance.post("/api/buyers/send_mobile_otp", data)
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}

export const getOTPOnEmail = async (data = {}) => {
    const resp = await scbAxiosInstance.post("/api/buyers/send_email_mobile_otp", data)
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}

export const register = async (data = {}) => {
    const resp = await scbAxiosInstance.post("/api/buyers/buyer_registration", data)
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}

export const verifyOTP = async (data = {}) => {
    const resp = await scbAxiosInstance.post("/api/buyers/submit_mobile_otp", data)
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}

export const verifyForgotPasswordOTP = async (data, transactionToken = "") => {
    const resp = await scbAxiosInstance.post('/reseller/users/verify_otp', data)
    .then(res => {
        return res;
    }).catch(error => {
        return error.response
    })
    return resp;
}

export const verifyLoginOTP = async (data) => {
    const alertStore = useAlertStore();
    const resp = await scbAxiosInstance.post('/api/users/login_through_otp', data)
    .then(res => {
        if (res.status === 200) {
            alertStore.setAlertMessage('success', 'Signed in successfully.', true)
        }        
        return res;
    }).catch(error => {
        return error.response
    })
    return resp;
}

export const forgotPassword = async (data) => {
    const resp = await scbAxiosInstance.post("/reseller/users/forgot_password", data).then(res => {
        return res;
    }).catch(error => {
        return error.response
    })
    return resp;
}

export const changePassword = (data) => {
    const resp = scbAxiosInstance.post("/reseller/users/change_password.json", data).then(res => {
        return res;
    }).catch(error => {
        return error.response
    })
    return resp;
}

export const resetPassword = (data) => {
    const resp = scbAxiosInstance.post("/reseller/users/reset_password.json", data).then(res => {
        return res;
    }).catch(error => {
        return error.response
    })
    return resp;
}